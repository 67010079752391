import React from "react";
import {
  Box,
  Typography,
} from "@cumplo/design-system";
import { coerceNumberProperty, coerceCurrencyProperty } from "../../../../utils/coercion";
import { montoTotalFinanciado, operacionesActivasPagador, operacionesMora, operacionesPagasTiempo, totalOperacionesPagador } from "../operacionesCL";
import * as styles from "../styles.module.css";

export default function AccordionCardCL({ pagador, currencyCode }) {

  return (
    <Box className={styles.card_accordion}>
      <Box className={styles.card_accordion_header}>
        <Typography>{pagador.nombre_pagador}</Typography>
      </Box>

      <Box className={styles.card_accordion_content}>
        <Box className={styles.card_accordion_header_secondary}>
          <Typography className={styles.card_accordion_header_secondary_text}>
            Participación en la oportunidad
          </Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Parte del pago</Typography>
          <Typography>{coerceNumberProperty(pagador.participacion)}%</Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Monto</Typography>
          <Typography>
            {coerceCurrencyProperty(pagador.monto_total, currencyCode)}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.card_accordion_content}>
        <Box className={styles.card_accordion_header_secondary}>
          <Typography className={styles.card_accordion_header_secondary_text}>
            Historial en Cumplo
          </Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Monto total financiado</Typography>
          <Typography>
            {coerceCurrencyProperty(montoTotalFinanciado(pagador.historial), currencyCode)}
          </Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Operaciones</Typography>
          <Typography>{totalOperacionesPagador(pagador.historial)}</Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Pagos al día</Typography>
          <Typography>{operacionesPagasTiempo(pagador.historial)}</Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Pagos en mora</Typography>
          <Typography>{operacionesMora(pagador.historial)}</Typography>
        </Box>

        <Box className={styles.card_content_row}>
          <Typography>Operaciones activas</Typography>
          <Typography>{operacionesActivasPagador(pagador.historial)}</Typography>
        </Box>
      </Box>

    </Box>
  );
}