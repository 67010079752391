import React from "react";
// COMPONENTS
import { Box, Grid, Typography } from "@cumplo/design-system";
// OTHERS
import {
  coerceCurrencyProperty,
  coerceDateLongProperty,
} from "../../../../utils/coercion";
import * as styles from "../../styles.module.css";

export default function FormaPago({ forma_pago = [], investmentDetail }) {
  const { moneda: currencyCode } = investmentDetail;

  const getName = () => {
    const numPagos = forma_pago.length;
    const suffix = numPagos > 1 ? "pagos" : "pago";
    const numPagosLeyenda = numPagos ? numPagos : "1";
    return `Recibirías tu retorno en ${numPagosLeyenda} ${suffix}`;
  };

  if (forma_pago.length < 2) return null;

  return (
    <Box mb="20px">
      <Box mb="4px">
        <Typography variant="body2" className={styles.subtitle}>
          {getName()}
        </Typography>
      </Box>

      <Box className={styles.payment}>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={styles.paymentTitle}>Vencimiento</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={styles.paymentTitle}>Interés</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography className={styles.paymentTitle}>
              Monto a recibir
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {forma_pago.map((cuota, i) => (
        <Box className={styles.payment} key={i}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={styles.body}>
                {coerceDateLongProperty(cuota.fecha_vencimiento, "-")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography className={styles.body}>
                {coerceCurrencyProperty(cuota.interes || 0, currencyCode)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography className={styles.body}>
                {coerceCurrencyProperty(cuota.monto_cuota || 0, currencyCode)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
