// extracted by mini-css-extract-plugin
export var MuiPaperRoot = "styles-module--MuiPaper-root--ff2db";
export var accordion = "styles-module--accordion--e4ffd";
export var accordionContainer = "styles-module--accordionContainer--e1639";
export var amount = "styles-module--amount--713e5";
export var amountQuantity = "styles-module--amountQuantity--6987d";
export var amountTitle = "styles-module--amountTitle--6f6e9";
export var balance_button = "styles-module--balance_button--8e425";
export var balance_desktop = "styles-module--balance_desktop--88e79";
export var balance_mobile = "styles-module--balance_mobile--68b22";
export var body = "styles-module--body--55af9";
export var breadcrumb = "styles-module--breadcrumb--78a92";
export var button = "styles-module--button--4fa86";
export var card = "styles-module--card--0591d";
export var cardContainer = "styles-module--cardContainer--9066f";
export var chartContainer = "styles-module--chartContainer--64c81";
export var chartLegend = "styles-module--chartLegend--e5a8e";
export var chip = "styles-module--chip--47c8b";
export var colorBox = "styles-module--colorBox--7e55b";
export var comision = "styles-module--comision--c8c14";
export var contact_alert = "styles-module--contact_alert--bd572";
export var dicom = "styles-module--dicom--bd267";
export var disclaimer = "styles-module--disclaimer--cb0b3";
export var investors = "styles-module--investors--49426";
export var legendRow = "styles-module--legendRow--7d50a";
export var mainTitle = "styles-module--mainTitle--977a0";
export var payment = "styles-module--payment--4205c";
export var paymentTitle = "styles-module--paymentTitle--58ed7";
export var receive = "styles-module--receive--31c10";
export var receiveQuantity = "styles-module--receiveQuantity--cfa69";
export var receiveTitle = "styles-module--receiveTitle--97a72";
export var simulatorCard = "styles-module--simulatorCard--8e0cf";
export var subtitle = "styles-module--subtitle--0ebee";
export var table = "styles-module--table--f0f07";
export var table_no_border = "styles-module--table_no_border--59fcc";
export var title = "styles-module--title--c4f3c";
export var total = "styles-module--total--cf5fe";