export const sortNumber = (items, property) => {
  return [...items].sort((x, y) => {
    if (x[property] < y[property]) {
      return 1;
    }
    if (x[property] > y[property]) {
      return -1;
    }
    return 0;
  });
}