import { useState } from "react";

import simulatorRestClient from "../service/simulator";

const useGetInvestmentDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [investmentDetail, setInvestmentDetail] = useState({});

  const getInvestmentDetail = async (id) => {
    setIsLoading(true);
    try {
      const result = await simulatorRestClient().getInvestmentDetail(id);
      setInvestmentDetail(result.data.data.attributes);
    } catch (error) {
      setInvestmentDetail({});
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    investmentDetail,
    getInvestmentDetail,
  };
};

export default useGetInvestmentDetail;
