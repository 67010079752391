import { coerceCurrencyProperty, coerceDateLongProperty, coerceNumberProperty } from '../../../../utils/coercion';
import { montoTotalFinanciado, totalOperacionesPagador } from '../operacionesCL';

function getOperaciones(data, currencyCode) {
    const items = [
        {
          etiqueta: 'En Cumplo desde',
          valor: coerceDateLongProperty(data.fecha_primera_operacion, '-'),
        },
        {
          etiqueta: 'Monto total financiado',
          valor: coerceCurrencyProperty(montoTotalFinanciado(data.historial), currencyCode)
        },
        {
          etiqueta: 'Operaciones',
          valor: totalOperacionesPagador(data.historial),
        },
        {
          etiqueta: 'Parte de pago',
          valor: `${coerceNumberProperty(data.participacion)}%`,
        },
        {
          etiqueta: 'Monto',
          valor: coerceCurrencyProperty(data.monto_total, currencyCode)
        },
    ];

    return items;
}

export default getOperaciones;
