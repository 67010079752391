import { coerceCurrencyProperty, coerceDateLongProperty } from "../../../../utils/coercion";
import { montoTotalSolicitado, totalOperacionesSolicitante } from "../operacionesCL";

function getOperaciones(data, currencyCode) {
    const items = [
      {
        etiqueta: 'En Cumplo desde',
        valor: coerceDateLongProperty(data.fecha_primera_operacion, '-'),
      },
      {
        etiqueta: 'Monto total solicitado',
        valor: coerceCurrencyProperty(montoTotalSolicitado(data.historial), currencyCode)
      },
      {
        etiqueta: 'Operaciones',
        valor: totalOperacionesSolicitante(data.historial),
      },
    ];

    return items;
}

export default getOperaciones;
