import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
 <Tooltip
  {...props}
  classes={{ popper: className }}
  enterTouchDelay={0}
  leaveTouchDelay={3000}
 />
))({
 [`& .${tooltipClasses.tooltip}`]: {
  maxWidth: 200,
  background: "#1B242C",
 },
});

export default CustomTooltip;
