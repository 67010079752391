import React from 'react';
import ControlBotones from '../ControlBotones';
// OTHERS
import listaPagosPE from "./listaPagosPE";
import listaPagosCL from "./listaPagosCL";
import { isCountryPeru } from '../../../../utils/country';

export default function EmpresaSolicitanteEstadoPagos({ solicitante = {} }) {
  const items = isCountryPeru()? listaPagosPE(solicitante) : listaPagosCL(solicitante);

  return <ControlBotones items={items} />;
}
