import { operacionesActivasPagador, operacionesMora, operacionesPagasTiempo } from "../operacionesCL";

function getPagos(pagador) {
    const items = [
        {
          etiqueta: 'Al día',
          valor: operacionesPagasTiempo(pagador.historial)
        },
        {
          etiqueta: 'En mora',
          valor: operacionesMora(pagador.historial),
        },
        {
          etiqueta: 'Operaciones activas',
          valor: operacionesActivasPagador(pagador.historial),
        },
    ];

    return items;
}

export default getPagos;
