import { useState, useContext } from "react";
import { SimulatorContext } from "../components/Simulator/SimulatorContext";

import simulatorRestClient from "../service/simulator";

const useGetSimulation = () => {
    const { selectedInvestorProfile } = useContext(SimulatorContext);

    const [isLoading, setIsLoading] = useState(false);
    const [simulation, setSimulation] = useState({});

    const getSimulation = async (
        productCode = "",
        currencyCode = "",
        amount = 0,
        term = 0,
        annualRate = 0,
        dues = 1,
        dueDate = "",
        operationId = null
    ) => {
        setIsLoading(true);
        try {
            const result = await simulatorRestClient().simultateInvestment(
                productCode,
                currencyCode,
                amount,
                term,
                annualRate,
                dues,
                dueDate,
                operationId,
                selectedInvestorProfile?.id
            );
            setSimulation(result.data.data.attributes);
        } catch (_) {
            setSimulation({});
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        simulation,
        getSimulation,
        setSimulation,
    };
};

export default useGetSimulation;
