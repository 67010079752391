import React, { useContext } from "react";
import { SimulatorContext } from "../../SimulatorContext";
// COMPONENTS
import { Alert, AlertTitle } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@cumplo/design-system";
import NotFoundCurrency from "./NotFoundCurrency";
// OTHERS
import { getUrlBalanceCumplo } from "../../../../utils/privateSite";
import { coerceCurrencyProperty, coerceNumberProperty } from "../../../../utils/coercion";
import * as styles from "./../../styles.module.css";

export default function Alerts({ balance, debouncedAmount, simulation, investmentDetail }) {
  const { selectedInvestorProfile } = useContext(SimulatorContext);
  const { name } = selectedInvestorProfile || {};
  const { moneda: currencyCode } = investmentDetail;

  const amountCommissionCumplo = () => {
    const commission = ["Puntos Cumplo", "Comisión Cumplo"];
    const valores =
      simulation?.costos?.valores?.filter((x) =>
      commission.includes(x.nombre)
      ) || [];
    return coerceNumberProperty(valores[0]?.valor);
  };

  const getTotalToPay = () => {
    const amount = coerceNumberProperty(debouncedAmount);
    return amount + amountCommissionCumplo();
  };

  const showAlertBalanceCumplo = () => {
    return balance.balance > getTotalToPay() && amountCommissionCumplo() > 0;
  }

  const showAlertPayToYourBalanceCumplo = () => {
    return balance.balance < getTotalToPay();
  }

  const missingBalance = () => {
    const amount = getTotalToPay() - balance.balance;
    return coerceCurrencyProperty(amount, currencyCode);
  }

  const navigateBalanceCumplo = () => {
    window.location = getUrlBalanceCumplo();
  };

  if (!balance) return <NotFoundCurrency currency={currencyCode} />;

  return (
    <div>
      {
        showAlertBalanceCumplo() && (
          <Alert
            className={styles.comision}
            severity="info"
            variant="outlined"
            icon={<InfoIcon />}
          >
            <AlertTitle>
              Esta inversión tiene
              <strong> {coerceCurrencyProperty(amountCommissionCumplo(), currencyCode)} </strong>
              de Comisión Cumplo
            </AlertTitle>
          </Alert>
        )}

      {
        showAlertPayToYourBalanceCumplo() && (
          <Alert
            className={styles.comision}
            severity="info"
            variant="outlined"
            icon={<InfoIcon />}
          >
            <AlertTitle>
              Tu Saldo Cumplo es menor que el monto de tu simulación
            </AlertTitle>
              Tienes {balance.balance$} en el Saldo Cumplo de la cuenta {name}. Haz un abono de
              <strong> {missingBalance()} </strong>
              o simula nuevamente.
            <Button
              className={styles.balance_button}
              variant="outlined"
              fullWidth
              onClick={navigateBalanceCumplo}
              id="ir_saldo_cumplo_btn"
            >
              Ir a Saldo Cumplo
            </Button>
          </Alert>
        )}
    </div>
  )
}