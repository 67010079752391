import React from "react";
import {
  Box,
  Typography,
} from "@cumplo/design-system";
import * as styles from "../styles.module.css";

export default function ControlBotones({ items = [] }) {
  return (
    <Box className={styles.cardContainer} mb="20px">
      {
        items.map(item => (
          <Box className={styles.card} minWidth="150px" key={item.etiqueta}>
            <Typography className={styles.investors}>
              {item.etiqueta}
            </Typography>

            <Typography className={styles.total}>
              {item.valor}
            </Typography>
          </Box>
        ))
      }
    </Box>
  )
}