import React from "react";
// COMPONENTS
import { Alert, AlertTitle } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
// OTHERS
import { NOT_CONTENT_TEXT } from "./Config";
import { isCountryChile } from "../../../../utils/country";
import * as styles from './../../styles.module.css';

export default function NotFoundCurrency({ currency }) {
  const { title, paragraphs, email, whatsApp } = NOT_CONTENT_TEXT[currency]  || {};
  if (isCountryChile()) return null;

  return (
    <Alert
      severity="info"
      variant="outlined"
      icon={<InfoIcon />}
    >
      <AlertTitle>{title}</AlertTitle>

      {paragraphs.map(paragraph => <p>{paragraph}</p>)}

      <footer className={styles.contact_alert}>
        <span>{email}</span>
        <span>{whatsApp}</span>
      </footer>
    </Alert>
  );
}
