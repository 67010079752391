import { operacionesActivasSolicitante } from "../operacionesCL";

function getPagos(solicitante) {
  const items = [
    {
      etiqueta: 'Operaciones activas',
      valor: operacionesActivasSolicitante(solicitante.historial),
    },
  ];

  return items;
}

export default getPagos;
