
import React from "react";
import {
  Box,
  Typography,
} from "@cumplo/design-system";
import { coerceCurrencyProperty } from "../../../../utils/coercion";
import * as styles from "../../styles.module.css";

export default function Desgloce({nombre, valor = 0, items = [], currencyCode}) {
  return (
    <Box mb="20px">
      <Box className={styles.table}>
        <Typography variant="body2" className={styles.subtitle}>
          {nombre}
        </Typography>

        <Typography variant="body2" className={styles.subtitle}>
          {coerceCurrencyProperty(valor, currencyCode)}
        </Typography>
      </Box>

      {
        (items || [])
          .map(item => (
            <Box className={styles.table} key={item.nombre}>
              <Typography variant="body2" className={styles.body}>
                {item.nombre}
              </Typography>

              <Typography variant="body2" className={styles.body}>
                {coerceCurrencyProperty(item.valor, currencyCode)}
              </Typography>
            </Box>
          ))
      }
    </Box>
  )
}