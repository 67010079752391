import { useState } from "react";
import axios from "axios";
import { session } from "cumplo_utils";
import { isCountryChile, isCountryPeru } from "../utils/country";
import { addfixedHeaders } from "../utils/addFixedHeaders";

export const useAuth = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [response, setResponse] = useState();

    const isValidCookie = (cookies) => {
        return (
            cookies.filter((x) => x.includes("_new_cumplo_session")).length ===
            1
        );
    };

    const login = ({ email, password }) => {
        const data = { identifier: email, password };
        setIsLoading(true);
        axios
            .post(`${process.env.GATSBY_HOST_URL}/auth/local`, data, {
                headers: { ...addfixedHeaders() },
            })
            .then((res) => {
                const { cookie, user, jwt } = res?.data || {};
                if (
                    (isCountryChile() && isValidCookie(cookie)) ||
                    isCountryPeru()
                ) {
                    const cookies = isCountryChile()
                        ? [cookie[1], cookie[2]]
                        : [];
                    session.setSession(jwt, cookies);
                    setResponse(user);
                } else {
                    setError("Usuario inválido");
                }
            })
            .catch((error) => {
                if (error?.code !== "ERR_NETWORK")
                    setError("Credenciales incorrectas");
                else setError("Error en el servidor");
            })
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        response,
        error,
        setError,
        login,
    };
};

export default useAuth;
