import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { Modal, useMediaQuery, InputAdornment } from "@mui/material";
import {
    Button,
    TextField,
    Box,
    ThemeProvider,
    Divider,
    Typography,
    FormControlLabel,
    Checkbox,
    Icon,
    Snackbar,
} from "@cumplo/design-system";
import CloseIcon from "@mui/icons-material/Close";
import * as styles from "./styles.module.css";
import { addfixedHeaders } from "../../../utils/addFixedHeaders";

const InvestFormModal = ({
    open,
    handleClose,
    idInvestment,
    amount,
    currency,
    userData,
}) => {
    const xs = useMediaQuery("(max-width:489px)");
    const xsh = useMediaQuery("(max-height:600px)");
    const md = useMediaQuery("(max-width:768px)");
    const lg = useMediaQuery("(max-width:1039px)");
    const url =
        "https://api-global.cumplo.com/inversionistas/solicitud-inversion";
    const key = "95260456-gebk-2651-ubpi-26d6ae761edb";

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackTitle, setSnackTitle] = useState("");
    const [snackBody, setSnackBody] = useState("");
    const [snackSeverity, setSnackSeverity] = useState("success");

    const validationSchema = Yup.object({
        isFirstTimeInvestment: Yup.bool(),
        nombre: Yup.string().required("El nombre es requerido"),
        apellido: Yup.string().required("El apellido es requerido"),
        dni: Yup.string().required("El DNI es requerido"),
        telefono: Yup.string().required("El teléfono es requerido"),
        email: Yup.string()
            .email("Ingresa un correo electrónico válido")
            .required("El correo electrónico es requerido"),
        idInversion: Yup.string().required(),
        monto: Yup.number()
            .typeError("El monto debe ser un número")
            .required("El monto es requerido")
            .min(100, "El monto mínimo a invertir es de 100"),
        moneda: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            isFirstTimeInvestment: false,
            nombre: "",
            apellido: "",
            dni: "",
            telefono: "",
            email: "",
            idInversion: idInvestment,
            monto: amount,
            moneda: currency,
        },
        validationSchema,
        onSubmit: async (values) => {
            axios
                .post(
                    url,
                    {
                        isFirstInvestment: values.isFirstTimeInvestment,
                        firstname: values.nombre,
                        lastname: values.apellido,
                        dni: values.dni,
                        phone:
                            "+51-" +
                            values.telefono.substr(0, 1) +
                            "-" +
                            values.telefono.substr(1, 4) +
                            "-" +
                            values.telefono.substr(5, 4),
                        email: values.email,
                        investmentId: values.idInversion,
                        amount: parseFloat(values.monto),
                        currency: values.moneda,
                    },
                    {
                        headers: {
                            "x-api-key": key,
                            ...addfixedHeaders(),
                        },
                    }
                )
                .then((res) => {
                    setSnackTitle("Solicitud de inversión enviada");
                    setSnackBody(
                        "Pronto te contactaremos con novedades de tu inversión."
                    );
                    setSnackSeverity("success");
                    setShowSnackBar(true);
                    formik.resetForm();
                    handleClose();
                })
                .catch((error) => {
                    setSnackTitle("Vuelve a intentarlo");
                    setSnackBody(
                        "No logramos enviar sus datos, inténtalo nuevamente."
                    );
                    setSnackSeverity("error");
                    setShowSnackBar(true);
                    console.error(error);
                });
        },
    });

    useEffect(() => {
        formik.setFieldValue("nombre", userData?.first_name);
        formik.setFieldValue("apellido", userData?.last_name);
        formik.setFieldValue("dni", userData?.dni);
        formik.setFieldValue("telefono", userData?.phone_number);
        formik.setFieldValue("email", userData?.email);
        formik.setFieldValue("idInversion", idInvestment);
        formik.setFieldValue("moneda", currency);
        formik.setFieldValue("monto", parseFloat(amount));
    }, [open, idInvestment, currency, amount]);

    return (
        <ThemeProvider>
            <Modal open={open} onClose={handleClose}>
                <Box
                    className={styles.boxModal}
                    width={xs ? "90vw" : md ? "65vw" : lg ? "45vw" : "35vw"}
                    height={xsh ? "90vh" : "auto"}
                >
                    <Box className={styles.closeIconContainer}>
                        <CloseIcon
                            name="close"
                            onClick={handleClose}
                            sx={{ cursor: "pointer" }}
                        />
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box mr="6px">
                            <Icon name="checkCircle" color="#555F6D" />
                        </Box>
                        <Typography variant="subtitle1" fontWeight={600}>
                            Déjanos tus datos para solicitar la inversión
                        </Typography>
                    </Box>

                    <Divider
                        className={`${styles.marginXNegative} ${styles.marginYPositive}`}
                    />

                    <form onSubmit={formik.handleSubmit}>
                        <Box className={styles.checkboxContainer}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isFirstTimeInvestment"
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "isFirstTimeInvestment",
                                                e.target.checked
                                            );
                                        }}
                                        label="Es primera vez que invierto en Cumplo."
                                        checked={
                                            formik.values.isFirstTimeInvestment
                                        }
                                    />
                                }
                                label="Es primera vez que invierto en Cumplo."
                            />
                        </Box>
                        <TextField
                            fullWidth
                            label="Nombre"
                            name="nombre"
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.nombre &&
                                Boolean(formik.errors.nombre)
                            }
                            helperText={
                                formik.touched.nombre && formik.errors.nombre
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="Apellido"
                            name="apellido"
                            value={formik.values.apellido}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.apellido &&
                                Boolean(formik.errors.apellido)
                            }
                            helperText={
                                formik.touched.apellido &&
                                formik.errors.apellido
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="DNI"
                            name="dni"
                            value={formik.values.dni}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.dni && Boolean(formik.errors.dni)
                            }
                            helperText={formik.touched.dni && formik.errors.dni}
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="Teléfono"
                            name="telefono"
                            value={formik.values.telefono}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.telefono &&
                                Boolean(formik.errors.telefono)
                            }
                            helperText={
                                formik.touched.telefono &&
                                formik.errors.telefono
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="Correo electrónico"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="ID de inversión"
                            name="idInversion"
                            disabled
                            value={idInvestment}
                            error={
                                formik.touched.idInversion &&
                                Boolean(formik.errors.idInversion)
                            }
                            helperText={
                                formik.touched.idInversion &&
                                formik.errors.idInversion
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="Monto a invertir"
                            name="monto"
                            type="number"
                            value={formik.values.monto}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.monto &&
                                Boolean(formik.errors.monto)
                            }
                            helperText={
                                formik.touched.monto && formik.errors.monto
                            }
                            sx={{ marginBottom: "12px" }}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        variant="body1"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                color: "#555F6D",
                                            },
                                        }}
                                    >
                                        {currency === "PEN" ? "S/" : "$"}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Divider className={styles.marginXNegative} />
                        <Box className={styles.buttonContainer}>
                            <Button variant="contained" type="submit">
                                Enviar solicitud
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Snackbar
                title={snackTitle}
                open={showSnackBar}
                severity={snackSeverity}
                onClose={() => setShowSnackBar(false)}
                body={snackBody}
                autoHideDuration={6000}
            />
        </ThemeProvider>
    );
};

export default InvestFormModal;
