import React, { useContext, useEffect } from "react";
// COMPONENTS
import { Box } from "@cumplo/design-system";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoginForm from "./LoginForm";
// SERVICES AND RECOVER DATA
import useAuth from "../../service/useAuth";
import { SimulatorContext } from "../Simulator/SimulatorContext";
// OTHERS
import * as styles from "./styles.module.css";

export default function LoginModal({ open, handleClose }) {
  const { isLoading, response, error, login, setError } = useAuth();
  const { setUser } = useContext(SimulatorContext);

  const siginUser = (credential) => {
    login(credential);
  };

  useEffect(() => {
    if (response) {
      setUser(response);
      handleClose();
    }
  }, [response]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styles.boxModal}>
        <Box className={styles.closeIconContainer}>
          <CloseIcon
            name="close"
            onClick={handleClose}
            sx={{ cursor: "pointer" }}
          />
        </Box>

        <LoginForm
          onSubmit={siginUser}
          error={error}
          setError={setError}
          isLoading={isLoading}
        />
      </Box>
    </Modal>
  );
}
