import { useState } from "react";
import axios from "axios";
import { coerceStringProperty } from "../utils/coercion";
import { cookie } from "cumplo_utils";
import { addfixedHeaders } from "../utils/addFixedHeaders";

const host = process.env.GATSBY_HOST_URL;

export const createReservePromise = (
    operationId,
    amount,
    selectedInvestorProfile
) => {
    const url = `${host}/CL/operacion-inversion/reservar`;
    const { value: jwt } = cookie.getCookie("cumploSession");
    const config = {
        headers: { Authorization: `Bearer ${jwt}`, ...addfixedHeaders() },
    };
    const data = {
        operacion: operationId,
        monto: amount,
        inversionista: selectedInvestorProfile.numberId,
    };
    return axios.post(url, { data }, config);
};

export const useCreateReserve = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const createReserve = (operationId, amount, selectedInvestorProfile) => {
        setLoading(true);
        setError("");
        createReservePromise(operationId, amount, selectedInvestorProfile)
            .then(({ data }) => {
                setResponse(data?.investment);
            })
            .catch((err) => {
                console.log(err);
                const textError = coerceStringProperty(
                    err?.response?.data?.error?.message,
                    "Error de servidor"
                );
                setError(textError);
                setResponse(null);
            })
            .finally(() => setLoading(false));
    };
    return { response, loading, error, createReserve };
};
