import React, { useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Chip,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ThemeProvider,
} from "@cumplo/design-system";
import { PieChart } from '@mui/x-charts/PieChart';
import * as styles from "../styles.module.css";
import { SimulatorContext } from "../SimulatorContext";
import { coerceStringProperty } from '../../../utils/coercion';
import { sortNumber } from "../../../utils/sort";
import EmpresaPagadoraOperaciones from "../Accordions/EmpresaPagadoraOperaciones/EmpresaPagadoraOperaciones.js";
import EmpresaPagadoraEstadoPagos from "../Accordions/EmpresaPagadoraEstadoPagos/EmpresaPagadoraEstadoPagos.js";
import AccordionOtrasEmpresasPagadoras from "../Accordions/AccordionOtrasEmpresasPagadoras/AccordionOtrasEmpresasPagadoras";
import EmpresaSolicitanteOperaciones from "../Accordions/EmpresaSolicitanteOperaciones/EmpresaSolicitanteOperaciones";
import EmpresaSolicitanteEstadoPagos from "../Accordions/EmpresaSolicitanteEstadoPagos/EmpresaSolicitanteEstadoPagos.js";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isCountryChile, isCountryPeru } from "../../../utils/country";

export default function InfoCard({ isLoading, investmentDetail }) {
  const getField = (fieldName) => {
    return applicantHistory.find( ({ tipo }) => tipo === fieldName ) || {cantidad: '0'};
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(768));
  const { selectedInvestmentData } = useContext(SimulatorContext);
  const pagadores = sortNumber(investmentDetail.pagadores, 'participacion') || [];
  const maxPagador = pagadores[0] || {};
  const applicantHistory = investmentDetail?.solicitante?.historial;
  const normalTerm = getField('pagados_plazo_normal');
  const current = getField('vigente');
  const paidArrears = getField('pagados_mora_mayor_30');
  const delayed = getField('mora_mayor_30');
  const { moneda: currencyCode } = investmentDetail;

  const data = [
    { id: 0, value: normalTerm.cantidad.replace("%",""), label: `${normalTerm.cantidad} fue pagado en plazo normal`, color: '#8737D0' },
    { id: 1, value: current.cantidad.replace("%",""), label: `${current.cantidad} es un monto activo`, color: '#33D165' },
    { id: 2, value: paidArrears.cantidad.replace("%",""), label: `${paidArrears.cantidad} fue pagado con mora mayor a 30 días`, color:'#80ABFF' },
    { id: 3, value: delayed.cantidad.replace("%",""), label: `${delayed.cantidad} está en mora mayor a 30 días`, color:'#FFACCD' },
  ];

  const sizing = {
    width: 200,
    height: 200,
    legend: { hidden: true },
  };

  return (
    <ThemeProvider>
      <Box sx={{ margin: { xs: "16px 0px 0px", md: "0px 0px" } }}>
        <Typography variant="body2" className={styles.title}>
          Información de la oportunidad
        </Typography>
        <Box mt="16px" className={styles.accordionContainer}>
          {/*  About */}
          <Accordion
            className={styles.accordion}
            defaultExpanded
          >
            <AccordionSummary
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography className={styles.title}>
                Sobre la oportunidad de inversión
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={styles.subtitle}>Respaldos</Typography>

              <Stack direction="row" flexWrap="wrap" spacing={1} my="10px" mb="20px">
                {(selectedInvestmentData?.operacion?.tipo_respaldo || [])
                  .map(x => (<Chip key={x} className={styles.chip} spacing={1} label={x} sx={{ margin: '4px 8px !important' }} />)
                  )}
              </Stack>

              <Box mb="8px">
                <Typography className={styles.subtitle}>
                  Forma de pago
                </Typography>
              </Box>
              <Box mb="20px">
                <Typography className={styles.body}>
                  {`${investmentDetail?.cuotas || 'Por'} ${investmentDetail?.cuotas > 1 ? "cuotas" : "cuota"
                    }`}
                </Typography>
                <Box className={styles.card} width="96px">
                  <Typography className={styles.investors}>
                    Inversionistas
                  </Typography>
                  <Typography className={styles.total}>
                    {investmentDetail?.cantidad_inversionistas}
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* EMPRESA PAGADORA */}
          {investmentDetail.pagadores.length > 0 && (
            <Accordion className={styles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={styles.title}>Empresa pagadora principal</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography className={styles.body}>
                  Es la responsable del pago del respaldo de la oportunidad de
                  inversión.
                </Typography>

                <Box my="20px">
                  <Typography className={styles.subtitle}>
                    {maxPagador?.nombre_pagador || "CRÉDITO DIRECTO"}
                  </Typography>
                </Box>

                <Box mb="16px">
                  <Typography className={styles.subtitle}>
                    Historial en Cumplo
                  </Typography>
                </Box>

                <Typography className={styles.body}>Operaciones</Typography>
                <EmpresaPagadoraOperaciones
                  data={maxPagador}
                  currencyCode={currencyCode}
                />

                {isCountryChile() && (
                  <>
                    <Typography className={styles.body}>Estados de pagos</Typography>
                    <EmpresaPagadoraEstadoPagos pagador={maxPagador} />
                  </>
                )}


                {/* TODO  SE FORZA A OCULTAR TEMPORALMENTE*/}
                {isCountryPeru() && false && (
                  <>
                    <Box mb="16px">
                      <Typography className={styles.subtitle}>
                        Descripción de la empresa:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={styles.body}>
                        {coerceStringProperty(maxPagador.descripcion, '-')}
                      </Typography>
                    </Box>

                    <Box mt="20px">
                      <Typography className={styles.subtitle}>
                        Giro de la empresa:
                      </Typography>
                    </Box>
                    <Box mt="16px">
                      <Typography className={styles.body}>
                        {coerceStringProperty(maxPagador.giro_detalle, '-')}
                      </Typography>
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {/* OTROS EMPRESAS PAGADORAS */}
          <AccordionOtrasEmpresasPagadoras
            pagadores={pagadores.slice(1)}
            currencyCode={currencyCode}
          />

            {/* EMPRESA SOLICITANTE */}
          {investmentDetail?.producto?.codigo !== 'EXTENSION_PLAZO_PAGO' && (
            <Accordion className={styles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={styles.title}>
                  Empresa solicitante
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Box mb="20px">
                  <Typography className={styles.body}>
                    Es la empresa que está solicitando el financiamiento a través de Cumplo
                  </Typography>
                </Box>

                <Box mb="16px">
                  <Typography className={styles.subtitle}>
                    Historial en Cumplo
                  </Typography>
                </Box>

                <Typography className={styles.body}>Operaciones</Typography>
                <EmpresaSolicitanteOperaciones
                  data={investmentDetail.solicitante}
                  currencyCode={currencyCode}
                />

                {isCountryChile() && (
                  <>
                    <Typography className={styles.body}>Estados de pagos</Typography>
                    <EmpresaSolicitanteEstadoPagos solicitante={investmentDetail.solicitante} />

                    <Box mb="16px">
                      <Typography className={styles.subtitle}>
                        Historial de montos financiados
                      </Typography>
                    </Box>
                    <Box className={styles.chartContainer}>
                      <PieChart
                        series={[
                          {
                            outerRadius: 80,
                            cx: 100,
                            cy: isSmallScreen ? 80 : 90,
                            data
                          },
                        ]}
                        {...sizing}
                      />
                      <Box className={styles.chartLegend}>
                        {data.map((legend, index) => (
                          <div key={index} className={styles.legendRow}>
                            <div  className={styles.colorBox} style={{backgroundColor: legend.color}}>{}</div>
                            <Typography>{legend?.label}</Typography>
                          </div>
                        ))}
                      </Box>
                    </Box>
                    <Box mb="16px">
                      <Typography className={styles.subtitle}>
                        Descripción de la empresa:
                      </Typography>
                    </Box>

                    <Box>
                      <Typography className={styles.body}>
                        {coerceStringProperty(investmentDetail.solicitante.descripcion, '-').trim()}
                      </Typography>
                    </Box>

                    <Box mt="20px">
                      <Typography className={styles.subtitle}>
                        Giro de la empresa:
                      </Typography>
                    </Box>

                    <Box mt="16px">
                      <Typography className={styles.body}>
                        {coerceStringProperty(investmentDetail.solicitante.giro_detalle, '-').trim()}
                      </Typography>
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
