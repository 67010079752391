import React, { useContext, useEffect } from "react";
import { Box, Grid, Container, ThemeProvider } from "@cumplo/design-system";
import InfoCard from "src/components/Simulator/InfoCard";
import Breadcrumb from "src/components/Simulator/Breadcrumb";
import InfoHeader from "src/components/Simulator/InfoHeader";
import SimulatorCard from "src/components/Simulator/SimulatorCard";
import useGetSimulation from "../hooks/useGetSimulation";
import { SimulatorContext } from "../components/Simulator/SimulatorContext";
import useGetInvestmentDetail from "../hooks/useGetInvestmentDetail";
import { navigate } from "gatsby-link";
import { getMinimunAmount } from "../components/Simulator/SimulatorCard/utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDebounce } from "use-debounce";
import ButtonInvestment from "../components/Simulator/ButtonInvestment/ButtonInvestment";
import { coerceCurrencyProperty } from "../utils/coercion";
import { isCountryChile } from "../utils/country";

export default function Simulator() {
    const { isLoading, simulation, getSimulation, setSimulation } =
        useGetSimulation();
    const opportunity =
        typeof window !== "undefined"
            ? new URL(window.location.href).searchParams.get("opportunityId")
            : null;

    const {
        isLoading: isLoadingDetail,
        investmentDetail,
        getInvestmentDetail,
    } = useGetInvestmentDetail();

    const {
        id_operacion: operationId,
        max_inversion: maxInvestmentAmount,
        cuotas: dues,
        fecha_vencimiento: dueDate,
        tasa_anual: annualRate,
        moneda: currencyCode,
        codigo_producto: productCode,
    } = investmentDetail;

    const {
        selectedInvestmentData,
        currentSimulationAmount,
        setCurrentSimulationAmount,
    } = useContext(SimulatorContext);

    useEffect(() => {
        if (selectedInvestmentData?.operacion?.id) {
            getInvestmentDetail(selectedInvestmentData?.operacion?.id);
        } else {
            const navigatePath = opportunity
                ? `/inversiones/?opportunityId=${opportunity}`
                : "/inversiones/";
            navigate(navigatePath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInvestmentData?.operacion?.id]);

    const simulate = async () => {
        setCurrentSimulationAmount(debouncedAmount);
        const { plazo } = selectedInvestmentData?.operacion || {};
        const plazoValue = (plazo && plazo?.value) || 0;
        getSimulation(
            productCode,
            currencyCode,
            parseFloat(debouncedAmount),
            plazoValue,
            annualRate,
            dues,
            dueDate,
            operationId
        );
    };

    const minimumAmount = getMinimunAmount(maxInvestmentAmount, currencyCode);

    const validationSchema = Yup.object({
        montoSimulacion: Yup.number()
            .typeError("El monto debe ser un número")
            .min(
                minimumAmount,
                `Ingresa un monto sobre los ${coerceCurrencyProperty(
                    minimumAmount,
                    currencyCode
                )}`
            )
            .max(
                maxInvestmentAmount,
                `El monto debe ser menor que ${coerceCurrencyProperty(
                    maxInvestmentAmount,
                    currencyCode
                )}`
            ),
    });

    const formik = useFormik({
        initialValues: {
            montoSimulacion: "",
        },
        validationSchema,
        onSubmit: async () => {
            simulate();
        },
    });

    const [debouncedAmount] = useDebounce(formik.values.montoSimulacion, 1200);

    if (
        !Object.entries(investmentDetail).length ||
        !Object.entries(selectedInvestmentData).length
    ) {
        return null;
    }

    return (
        <ThemeProvider>
            <Box
                mt="120px"
                sx={{
                    minHeight: "calc(100vh - 524px)",
                    padding: { xs: "0px 16px 25px", md: "0px" },
                }}
            >
                <Container sx={{ padding: { xs: "0px", md: "20px 80px" } }}>
                    <Breadcrumb
                        link={
                            isCountryChile()
                                ? "/oportunidades-de-inversion"
                                : "/inversiones"
                        }
                    />
                    <Box my="24px">
                        <Grid
                            container
                            sx={{ justifyContent: "space-between" }}
                            position="relative"
                        >
                            <Grid item xs={12} sm={12} lg={6}>
                                <InfoHeader
                                    investmentDetail={investmentDetail}
                                    isLoading={isLoadingDetail}
                                    activateProgressBar
                                />
                                <Box sx={{ display: { md: "none" } }}>
                                    <SimulatorCard
                                        isLoading={isLoading}
                                        simulation={simulation}
                                        investmentDetail={investmentDetail}
                                        getSimulation={getSimulation}
                                        resetSimulation={setSimulation}
                                        formik={formik}
                                        debouncedAmount={debouncedAmount}
                                    />
                                </Box>
                                <InfoCard
                                    isLoading={isLoadingDetail}
                                    investmentDetail={investmentDetail}
                                />
                                <Box
                                    mt="24px"
                                    sx={{
                                        display: { md: "none" },
                                        position: "sticky",
                                        bottom: "0",
                                        background: "white",
                                        padding: "20px",
                                        paddingRight: "40px",
                                        zIndex: 2,
                                    }}
                                >
                                    <ButtonInvestment
                                        formik={formik}
                                        simulation={simulation}
                                        isLoading={isLoading}
                                        investmentDetail={investmentDetail}
                                        debouncedAmount={debouncedAmount}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                                <Box
                                    sx={{
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    <SimulatorCard
                                        isLoading={isLoading}
                                        simulation={simulation}
                                        getSimulation={getSimulation}
                                        resetSimulation={setSimulation}
                                        investmentDetail={investmentDetail}
                                        formik={formik}
                                        debouncedAmount={debouncedAmount}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}
