import React from 'react';
import ControlBotones from '../ControlBotones';
//OTHERS
import listaOperacionesPE from "./listaOperacionesPE";
import listaOperacionesCL from "./listaOperacionesCL";
import { isCountryPeru } from '../../../../utils/country';

export default function EmpresaSolicitanteOperaciones({ data = {}, currencyCode }) {
  const items = isCountryPeru()? listaOperacionesPE(data, currencyCode) : listaOperacionesCL(data, currencyCode);

  return <ControlBotones items={items} />;
}



