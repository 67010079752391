import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@cumplo/design-system";
import AccordionCardPE from "./AccordionCardPE";
import AccordionCardCL from "./AccordionCardCL";
import { isCountryPeru } from '../../../../utils/country';
import * as styles from "../styles.module.css";

export default function AccordionOtrasEmpresasPagadoras({ pagadores = [], currencyCode }) {
  if (pagadores.length === 0 ) return null;

  const showAccordionCard = (pagador, currencyCode) => {
    return isCountryPeru()
      ? <AccordionCardPE key={pagador.nombre_pagador} pagador={pagador} currencyCode={currencyCode} />
      : <AccordionCardCL key={pagador.nombre_pagador} pagador={pagador} currencyCode={currencyCode} />
  }

  return (
    <Accordion className={styles.accordion} >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={styles.title}>
          Otras empresas pagadoras
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {pagadores.map(pagador => showAccordionCard(pagador, currencyCode))}
      </AccordionDetails>
    </Accordion >
  );
}
// 