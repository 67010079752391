import React from 'react';
import ControlBotones from '../ControlBotones';
import listaPagosPE from "./listaPagosPE";
import listaPagosCL from "./listaPagosCL";
import { isCountryPeru } from '../../../../utils/country';

export default function EmpresaPagadoraEstadoPagos({ pagador = {} }) {
  const items = isCountryPeru()? listaPagosPE(pagador) : listaPagosCL(pagador);

  return <ControlBotones items={items} />;
}
