import React, { useEffect } from "react";
import { useFormik } from "formik";
// COMPONENTS
import {
  TextField,
  Button,
  Typography,
  Box,
  Link,
} from "@cumplo/design-system";
import { Alert } from "@mui/material";
// OTHERS
import { validationSchema } from "./validationSchema";
import {
  getUrlCreateAccount,
  getUrlRecoverPassword,
} from "../../utils/privateSite";
import { isCountryPeru } from "../../utils/country";
import * as styles from "./styles.module.css";

export default function LoginForm({ onSubmit, error, setError, isLoading }) {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);

  const navigateRecoverPassword = () => {
    window.location = getUrlRecoverPassword();
  };

  const navigateCreateAccount = () => {
    window.location = getUrlCreateAccount();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h6">Ingresa a tu cuenta inversionista</Typography>

      {error && <Alert severity="error">{error}</Alert>}

      <TextField
        id="email"
        name="email"
        label="Correo electrónico"
        fullWidth
        value={formik.values.email}
        autoComplete="off"
        onChange={(e) => {
          formik.handleChange(e);
          setError(null);
        }}
        error={
          (formik.touched.email && Boolean(formik.errors.email)) ||
          error === "ValidationError"
        }
        helperText={formik.touched.email && formik.errors.email}
      />

      <TextField
        id="password"
        name="password"
        label="Contraseña"
        type="password"
        fullWidth
        value={formik.values.password}
        autoComplete="off"
        onChange={(e) => {
          formik.handleChange(e);
          setError(null);
        }}
        error={
          (formik.touched.password && Boolean(formik.errors.password)) ||
          error === "ValidationError"
        }
        helperText={formik.touched.password && formik.errors.password}
      />

      <Box className={styles.form_actions}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={
            !formik.values.email || !formik.values.password || isLoading
          }
          id="modal_ingresar_btn"
        >
          Ingresar
        </Button>

        <Link
          variant="subtitle2"
          disabled={isLoading}
          className={styles.link}
          onClick={navigateRecoverPassword}
        >
          Olvidé mi contraseña
        </Link>
      </Box>

      {isCountryPeru() && (
        <Box className={styles.form_no_account}>
          <Typography variant="body2" className={styles.recover}>
            ¿Es tu primera vez en Cumplo?
          </Typography>

          <Link
            variant="body2"
            disabled={isLoading}
            className={`${styles.link} ${styles.recover}`}
            onClick={navigateCreateAccount}
          >
            Crea tu cuenta
          </Link>
        </Box>
      )}
    </form>
  );
}
