import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
 header: {
  display: "flex",
 },
 imgContainer: {
  width: "100%",
  height: "90px",
  display: "flex",
  maxWidth: "90px",
  padding: "4px 2px",
  borderRadius: "4px",
  alignItems: "center",
  border: "1px solid #CFD6DD",
 },
 image: {
  width: "100%",
 },
 id: {
  color: "#555F6D",
 },
 text: {
  color: "#1B242C",
 },
 companyName: {
  color: "#1B242C",
 },
 price: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
 },
 available: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
 },
 marginVertical: {
  marginTop: '12px !important',
  marginBottom: '12px !important'
 },
 progressBar: {
  "&.MuiLinearProgress-root": {
   height: 8,
   borderRadius: 5,
    "& > .MuiLinearProgress-bar2Buffer": {
      backgroundColor: "#b2ddc1"
    },
  },
 },
 percentage: {
  color: "#555F6D",
 },
 flex: {
  display: "flex",
 },
 taxes: {
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1.2rem",
  marginBottom: "1.5rem",
 },
 tax: {
  color: "#1B242C",
  "&.MuiTypography-root": {
   fontSize: "16px",
   fontWeight: 600,
  },
 },
 taxValue: {
  color: "#1B242C",
  "&.MuiTypography-root": {
   marginLeft: "3px",
   fontSize: "16px",
   lineHeight: "20px",
  },
 },
 tooltipText: {
  marginBottom: "10px",
 },
 [theme.breakpoints.down("md")]: {
  simulatorTitle: {
   color: "#1B242C",
   "&.MuiTypography-root": {
    fontSize: "16px",
    fontWeight: 600,
   },
  },
  imgContainer: {
   height: "56px",
   maxWidth: "56px",
  },
  id: {
   "&.MuiTypography-root": {
    fontSize: "14px",
   },
  },
  companyName: {
   "&.MuiTypography-root": {
    fontSize: "14px",
   },
  },
  tax: {
   "&.MuiTypography-root": {
    fontSize: "14px",
   },
  },
  taxValue: {
   "&.MuiTypography-root": {
    fontSize: "14px",
   },
  },
 },
}));
