import React, { useContext, useEffect } from "react";
import { Box, Typography, ThemeProvider } from "@cumplo/design-system";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

import { SimulatorContext } from "../SimulatorContext";
// COMPONENTS
import Desgloce from "./components/Desgloce";
import FormaPago from "./components/FormPago";
import ButtonInvestment from "../ButtonInvestment/ButtonInvestment";
import Alerts from "./components/Alerts";
import BalanceCumplo from "./components/BalanceCumplo/BalanceCumplo";
import MaskCurrencyAmountInput from "./components/MaskCurrencyAmountInput";
// OTHERS
import {
    coerceCurrencyProperty,
    coerceNumberProperty,
} from "../../../utils/coercion";
import { getMinimunAmount } from "./utils";
import * as styles from "../styles.module.css";

export default function SimulatorCard(props) {
    const {
        isLoading,
        simulation,
        resetSimulation,
        formik,
        debouncedAmount,
        investmentDetail,
    } = props;
    const { balanceCumplo, isAuth } = useContext(SimulatorContext);
    const { moneda: currencyCode, max_inversion: maxInvestmentAmount } =
        investmentDetail;
    const minimumAmount = getMinimunAmount(maxInvestmentAmount, currencyCode);

    const handleChange = (e) => {
        formik.setFieldValue("montoSimulacion", e.target.value);
    };

    const balance = balanceCumplo.filter(
        (x) => x.currencyCode === currencyCode
    )[0];

    useEffect(() => {
        if (Boolean(formik.errors.montoSimulacion)) {
            resetSimulation({});
        }
        if (debouncedAmount && debouncedAmount > 0) {
            formik.submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAmount]);

    return (
        <ThemeProvider>
            <Box>
                {balance && (
                    <div className={styles.balance_desktop}>
                        <BalanceCumplo balance={balance} />
                    </div>
                )}

                <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <Typography variant="body2" className={styles.mainTitle}>
                        Simula tu inversión
                    </Typography>
                </Box>

                <Box mt="20px" className={styles.simulatorCard}>
                    <Box mb="12px">
                        <Typography variant="body2" className={styles.title}>
                            ¿Cuánto quieres simular?
                        </Typography>
                    </Box>

                    <Box mb="16px">
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    height: "20px",
                                    marginTop: -1,
                                },
                                "& .MuiInputBase-input": {
                                    padding: "16px",
                                    paddingBottom: "8px",
                                },
                            }}
                            autoFocus
                            fullWidth
                            label="Ingresa el monto que quieres invertir"
                            name="montoSimulacion"
                            value={formik.values.montoSimulacion.toString()}
                            onChange={handleChange}
                            error={
                                formik.touched.montoSimulacion &&
                                Boolean(formik.errors.montoSimulacion)
                            }
                            helperText={
                                formik.touched.montoSimulacion &&
                                Boolean(formik.errors.montoSimulacion)
                                    ? formik.errors.montoSimulacion
                                    : `Puedes invertir desde ${coerceCurrencyProperty(
                                          minimumAmount,
                                          currencyCode
                                      )}`
                            }
                            disabled={
                                coerceNumberProperty(
                                    investmentDetail.max_inversion
                                ) <= 0
                            }
                            InputProps={{
                                inputComponent: MaskCurrencyAmountInput,
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        variant="body1"
                                        sx={{ paddingTop: 1 }}
                                    >
                                        {currencyCode === "PEN" ? "S/" : "$"}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box mb="16px" className={styles.receive}>
                        <Box mb="12px">
                            <Typography
                                variant="body2"
                                className={styles.receiveTitle}
                            >
                                Recibirías
                            </Typography>
                        </Box>
                        <Typography
                            variant="body2"
                            className={styles.receiveQuantity}
                        >
                            {coerceCurrencyProperty(
                                simulation?.rentabilidad?.total || 0,
                                currencyCode
                            )}
                        </Typography>
                    </Box>

                    <Desgloce
                        nombre="Intereses"
                        valor={simulation?.intereses?.total}
                        currencyCode={currencyCode}
                    />

                    <Desgloce
                        nombre="Costos"
                        valor={simulation?.costos?.total}
                        items={simulation?.costos?.valores}
                        currencyCode={currencyCode}
                    />

                    <Desgloce
                        nombre="Utilidad"
                        valor={simulation?.ganancia_liquida}
                        currencyCode={currencyCode}
                    />

                    <FormaPago
                        forma_pago={simulation?.forma_pago}
                        investmentDetail={investmentDetail}
                    />

                    {isAuth && (
                        <Alerts
                            balance={balance}
                            debouncedAmount={debouncedAmount}
                            simulation={simulation}
                            investmentDetail={investmentDetail}
                        />
                    )}
                </Box>

                <Box mt="20px" sx={{ display: { xs: "none", md: "block" } }}>
                    <ButtonInvestment
                        formik={formik}
                        simulation={simulation}
                        isLoading={isLoading}
                        investmentDetail={investmentDetail}
                        debouncedAmount={debouncedAmount}
                    />
                </Box>

                <Box mt="20px" sx={{ paddingRight: { xs: "0", md: "28px" } }}>
                    <Typography className={styles.disclaimer}>
                        * Los valores y fechas de pago de las cuotas son
                        estimados. El desarrollo definitivo del crédito
                        dependerá del día de firma de pagaré y estará disponible
                        para los inversionistas en el detalle de cada una de sus
                        inversiones.
                    </Typography>
                    <Typography className={styles.disclaimer}>
                        * Los intereses de este crédito comenzarán a generarse
                        el día en que se financie.
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
