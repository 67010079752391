import React, { useContext } from "react";
import { SimulatorContext } from "../SimulatorContext";
// COMPONENTS
import InfoIcon from "@mui/icons-material/Info";
import FallbackEnterprise from 'src/assets/images/fallbackEnterprise.png'
import { Typography, Box } from "@cumplo/design-system";
import CustomTooltip from "./customTooltip";
import LinearProgress from '@mui/material/LinearProgress';
import BalanceCumplo from "../SimulatorCard/components/BalanceCumplo/BalanceCumplo";
// OTHERS
import {
  coerceCurrencyProperty,
  coerceDateLongProperty,
  coerceNumberProperty,
  coerceNumberWithDecimalsProperty
} from "../../../utils/coercion";
import { isCountryChile } from "../../../utils/country";
import { useStyles } from "./styles";
import * as styles from "../styles.module.css";

const environmentLocale = typeof window !== 'undefined' ? window.environmentLocale : ''; // FIX FOR GASBY

const InfoHeader = ({ activateProgressBar, investmentDetail }) => {
  const classes = useStyles();
  const { balanceCumplo, selectedInvestmentData } = useContext(SimulatorContext);
  const {
    monto_financiar,
    max_inversion, porcentaje_inversion,
    porcentaje_reservado,
    monto_reservado,
    tasa_anual,
    tir,
    moneda: currencyCode,
  } = investmentDetail || {};

  const porcentajeInversion = coerceNumberProperty(porcentaje_inversion);
  const porcentajeReservado = coerceNumberProperty(porcentaje_reservado);
  const montoReservado$ = coerceCurrencyProperty(monto_reservado, currencyCode);
  const balance = balanceCumplo.filter(x => x.currencyCode === currencyCode)[0];


  return (
    <Box>
      <Box mt="8px" mb="24px" sx={{ display: { md: "none" } }}>
        <Typography variant="body2" className={classes.simulatorTitle}>
          Simula tu inversión
        </Typography>
      </Box>

      {balance && (
        <div className={styles.balance_mobile}>
          <BalanceCumplo balance={balance} />
        </div>
      )}

      <Box gap={2} className={classes.header} mb="20px">
        <Box className={classes.imgContainer}>
          <img
            className={classes.image}
            src={selectedInvestmentData?.empresa?.logo || FallbackEnterprise}
            alt="logo de empresa"
          />
        </Box>
        <Box>
          <Typography variant="body2" className={classes.id}>
            ID {selectedInvestmentData?.operacion?.id}
          </Typography>
          <Typography
            variant="h4"
            className={classes.companyName}
            sx={{ fontWeight: 600, fontSize: "24px" }}
          >
            {selectedInvestmentData?.empresa?.nombre_fantasia || "CRÉDITO DIRECTO"}
          </Typography>
        </Box>
      </Box>
      <Box pt="18px" pb="16px">
        <Box className={classes.price}>
          <Typography variant="body1" className={classes.text}>
            Monto a financiar
          </Typography>
          <Typography
            variant="body1"
            className={classes.text}
          >
            {coerceCurrencyProperty(monto_financiar, currencyCode)}
          </Typography>
        </Box>

        <Box mt="6px" mb="4px">
          {activateProgressBar && (
            <LinearProgress
              variant="buffer"
              value={porcentajeInversion}
              valueBuffer={porcentajeInversion + porcentajeReservado}
              className={classes.progressBar}
            />
          )}
        </Box>

        {isCountryChile() && (
          <Box className={classes.price}>
            <Typography variant="body1" className={classes.text}>
              Reservado
              <CustomTooltip
                title={
                  <div className={classes.tooltipText}>
                    Otras personas están intentando invertir usando este monto, pero podrían no terminar con el abono.
                    Esto podría tardar máximo 10 minutos. Espera y vuelve a revisar esta oportunidad.
                  </div>
                }
              >
                <InfoIcon
                  sx={{
                    fontSize: 14,
                    color: "#7E8B99",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Typography>
            <Typography variant="body1" className={classes.text}>
              {montoReservado$}
            </Typography>
          </Box>
        )}

        <Box className={classes.available}>
          <Typography variant="subtitle1" className={classes.text} fontWeight={500}>
            Disponible para invertir
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.text}
            fontWeight={500}
          >
            {coerceCurrencyProperty(max_inversion, currencyCode)}
          </Typography>
        </Box>

        <Box className={[classes.flex, classes.taxes]}>
          <Box
            mb="4px"
            className={classes.flex}
            sx={{
              alignItems: "center",
              flexBasis: { xs: "50%", md: "inherit" },
              order: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="body2" className={classes.tax}>
              Tasa anual:
            </Typography>

            <Typography variant="body2" className={classes.taxValue}>
              {coerceNumberWithDecimalsProperty(tasa_anual, environmentLocale)}%
            </Typography>
            <CustomTooltip
              title={
                <div className={classes.tooltipText}>
                  Indicador del retorno que puedes obtener al invertir dinero.
                </div>
              }
            >
              <InfoIcon
                sx={{
                  fontSize: 14,
                  color: "#7E8B99",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              />
            </CustomTooltip>
          </Box>

          { isCountryChile() &&
            <Box
              mb="4px"
              className={classes.flex}
              sx={{
                alignItems: "center",
                flexBasis: { xs: "50%", md: "inherit" },
                order: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="body2" className={classes.tax}>
                TIR:
              </Typography>
              <Typography variant="body2" className={classes.taxValue}>
                {coerceNumberWithDecimalsProperty(tir, environmentLocale)}%
              </Typography>
              <CustomTooltip
                title={
                  <div className={classes.tooltipText}>
                    La Tasa Interna de Retorno (TIR) mide la utilidad de una oportunidad de inversión. A mayor TIR, mejores retornos.
                    Considera el interés de acuerdo al capital invertido más los flujos y el tiempo en que los montos son pagados.
                  </div>
                }
              >
                <InfoIcon
                  sx={{
                    fontSize: 14,
                    color: "#7E8B99",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Box>
          }
          <Box
            mb="4px"
            className={classes.flex}
            sx={{
              flexBasis: { xs: "50%", md: "inherit" },
              order: { xs: 1, md: 0 },
            }}
          >
            <Typography variant="body2" className={classes.tax}>
              Plazo:
            </Typography>

            <Typography variant="body2" className={classes.taxValue}>
              {selectedInvestmentData?.operacion?.plazo?.value || 0}
              {selectedInvestmentData?.operacion?.plazo?.type === 'day' && ' días'}
              {selectedInvestmentData?.operacion?.plazo?.type === 'month' && ' meses'}
            </Typography>

          </Box>
          <Box
            mb="4px"
            className={classes.flex}
            sx={{
              flexBasis: { xs: "50%", md: "inherit" },
              order: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="body2" className={classes.tax}>
              Vencimiento:
            </Typography>
            <Typography variant="body2" className={classes.taxValue}>
              {coerceDateLongProperty(investmentDetail?.fecha_vencimiento)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoHeader;
