import React from 'react';
// COMPONENTS
import { Typography } from "@cumplo/design-system";
// OTHERS
import { coerceDateLongProperty } from "../../../../../utils/coercion";
import { FORMAT_DATES } from "../../../../../constants/Dates";
import "./styles.css";

export default function BalanceCumplo({ balance }) {
  return (
    <div className="balance">
      <div className="balance_content_label">
        <Typography className="text_secondary" variant="caption">
          {/* {coerceDateLongProperty(new Date(), "-", FORMAT_DATES.long)} */}
        </Typography>

        <div className="balance_content_title">
          <Typography className="text_secondary" variant="body2">
            Saldo Cumplo
          </Typography>
        </div>
      </div>

      <div className="balance_content_value">
        <Typography variant="subtitle2">{balance.balance$}</Typography>
      </div>
    </div>
  );
}
