export const getMinimunAmount = (maxAmount, currencyCode) => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && window.environmentCountry === "CL" && currencyCode === 'CLP') {
        return maxAmount < 100000 ? maxAmount : 100000;
    } else if (isBrowser && window.environmentCountry === "PE" && currencyCode === 'PEN') {
        return maxAmount < 400 ? maxAmount : 400;
    } else if (isBrowser && window.environmentCountry === "PE" && currencyCode === 'USD') {
        return maxAmount < 100 ? maxAmount : 100;
    } else {
        return 0;
    }
};