import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import * as styles from "../styles.module.css";

import { ReactComponent as Arrow } from "src/assets/svg/arrow.svg";
import { navigate } from "gatsby-link";

const Breadcrumb = ({link}) => {
 return (
  <Box>
   <Arrow />
   <Link onClick={()=>navigate(link)} className={styles.breadcrumb}>
        Volver a Oportunidades de inversión
   </Link>
  </Box>
 );
};

export default Breadcrumb;
