import { coerceCurrencyProperty, coerceDateLongProperty } from '../../../../utils/coercion';
import { montoTotalFinanciado, operacionesPagadasMora, operacionesPagasPlazoNormal, totalOperacionesPagador } from '../operacionesPE';

function getOperaciones(data, currencyCode) {
    const items = [
        {
          etiqueta: 'En Cumplo desde',
          valor: coerceDateLongProperty(data.fecha_primera_operacion, '-'),
        },
        {
          etiqueta: 'Monto total financiado',
          valor: coerceCurrencyProperty(montoTotalFinanciado(data.historial), currencyCode)
        },
        {
          etiqueta: 'Operaciones',
          valor: totalOperacionesPagador(data.historial),
        },
        {
          etiqueta: 'Pagado en plazo normal',
          valor: operacionesPagasPlazoNormal(data.historial),
        },
        {
          etiqueta: 'Pagado en mora',
          valor: operacionesPagadasMora(data.historial),
        }
    ];

    return items;
}

export default getOperaciones;
