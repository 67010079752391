export const NOT_CONTENT_TEXT = {
  PEN: {
    title: 'Habilita tu saldo en soles.',
    paragraphs: [
      'Contacta a tu representante comercial para poder abonar e invertir en soles. Necesitas una cuenta bancaria a tu nombre.'
    ],
    email: 'Correo: cumploperu@cumplo.com',
    whatsApp: 'WhatsApp: +51 914 451 815'
  },
  USD: {
    title: 'Habilita tu saldo en dólares.',
    paragraphs: [
      'Contacta a tu representante comercial para poder abonar e invertir en dólares. Necesitas una cuenta bancaria a tu nombre.'
    ],
    email: 'Correo: cumploperu@cumplo.com',
    whatsApp: 'WhatsApp: +51 914 451 815'
  },
  CLP: {
    title: 'Habilita tu saldo.',
    paragraphs: [
      'Contacta a tu representante comercial para poder abonar e invertir. Necesitas una cuenta bancaria a tu nombre.'
    ],
    email: 'Correo: cumplochile@cumplo.com',
    whatsApp: 'WhatsApp: +51 914 451 815'
  }
}