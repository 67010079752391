import React, { useContext, useState, useEffect } from "react";
import { Button } from "@cumplo/design-system";
import { SimulatorContext } from "../SimulatorContext";
import InvestFormModal from "../../Oportunidades/InvestFormModal";
import LoginModal from "../../Login/LoginModal";
import { coerceNumberProperty } from "../../../utils/coercion";
import { useCreateReserve } from "../../../hooks/useCreateReserve";
import { getUrlPrivateSite } from "../../../utils/privateSite";
import { isCountryPeru } from "../../../utils/country";
import * as styles from "./styles.module.css";

export default function ButtonInvestment({
    formik,
    simulation,
    isLoading,
    investmentDetail,
    debouncedAmount,
}) {
    const [isOpenLogin, setOpenLogin] = useState(false);
    const [showFormRegisterPeru, setFormRegisterPeru] = useState(false);
    const {
        selectedInvestmentData,
        currentSimulationAmount,
        balanceCumplo,
        isAuth,
        selectedInvestorProfile,
        user,
        setLoading,
    } = useContext(SimulatorContext);

    const { id_operacion: operationId, moneda: currencyCode } =
        investmentDetail;
    const balance = balanceCumplo.filter(
        (x) => x.currencyCode === currencyCode
    )[0];
    const { response: reserve, loading, createReserve } = useCreateReserve();

    const handleCloseLogin = () => {
        setOpenLogin(false);
    };

    const goToInvestPeru = () => {
        const host = getUrlPrivateSite();
        const params = [];
        params.push(`operacion/${operationId}`);
        params.push(`monto/${debouncedAmount}`);
        params.push(`perfil/${selectedInvestorProfile.numberId}`);
        params.push(`enterpriseid/${selectedInvestorProfile.id}`);
        const url = `${host}/invertir-oportunidad/${params.join("/")}`;
        window.location = url;
    };

    const handleBtnInvestNow = () => {
        if (isCountryPeru()) {
            goToInvestPeru();
        } else {
            createReserve(
                operationId,
                debouncedAmount,
                selectedInvestorProfile
            );
        }
    };

    const isActionsDisabled = () => {
        return (
            isLoading ||
            !formik.values.montoSimulacion ||
            !!formik.errors.montoSimulacion ||
            !simulation?.rentabilidad?.total ||
            simulation?.rentabilidad?.total <= 0 ||
            loading ||
            isDisabledBtnForBalanceCumplo()
        );
    };

    const amountComisionCumplo = () => {
        const comision = ["Puntos Cumplo", "Comisión Cumplo"];
        const valores =
            simulation?.costos?.valores?.filter((x) =>
                comision.includes(x.nombre)
            ) || [];
        return coerceNumberProperty(valores[0]?.valor);
    };

    const getTotalToPay = () => {
        const amount = coerceNumberProperty(debouncedAmount);
        return amount + amountComisionCumplo();
    };

    const isDisabledBtnForBalanceCumplo = () => {
        if (!isAuth || balanceCumplo.length === 0) {
            return false;
        }
        const totalToPay = getTotalToPay();
        return totalToPay > balance.balance;
    };

    useEffect(() => {
        if (reserve) {
            const host = getUrlPrivateSite();
            const url = `${host}/invertir/${selectedInvestorProfile.numberId}/${selectedInvestorProfile.id}`;
            window.location = url;
        }
    }, [reserve]);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <>
            <div className={styles.action_group}>
                {isAuth && (
                    <Button
                        id="invertir-btn"
                        variant="contained"
                        fullWidth
                        disabled={!balance || isActionsDisabled()}
                        onClick={() => handleBtnInvestNow()}
                    >
                        Invertir ahora
                    </Button>
                )}

                {!isAuth && (
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={isActionsDisabled()}
                        onClick={() => setOpenLogin(true)}
                    >
                        Ingresar para invertir
                    </Button>
                )}

                {!isAuth && isCountryPeru() && (
                    <Button
                        variant="outlined"
                        fullWidth
                        disabled={isActionsDisabled()}
                        onClick={() => setFormRegisterPeru(true)}
                    >
                        Solicitar inversión
                    </Button>
                )}
            </div>

            <LoginModal open={isOpenLogin} handleClose={handleCloseLogin} />

            <InvestFormModal
                open={showFormRegisterPeru}
                handleClose={() => setFormRegisterPeru(false)}
                idInvestment={selectedInvestmentData?.operacion?.id}
                currency={selectedInvestmentData?.operacion?.moneda}
                amount={currentSimulationAmount}
                userData={user}
            />
        </>
    );
}
